import dagre from "@dagrejs/dagre";

const nodeWidth = 150;
const nodeHeight = 90;
const concurrentNodeGap = 260;
const colorList = [
  "#00CD90",
  "#4AA2FF",
  "#F8C557",
  "#E381FF",
  "#FF9D89",
  "#FF7DA2",
];

export const getLayoutedElements = (
  nodes,
  edges,
  bucketPositions,
  direction = "TB"
) => {
  const dagreGraph = new dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));

  return new Promise((resolve, reject) => {
    try {
      const isHorizontal = direction === "LR";
      let categories = Object.keys(bucketPositions);
      dagreGraph.setGraph({ rankdir: "TB", nodesep: 20, ranksep: 200 });

      nodes.forEach((node) => {
        if (!node.id.includes("-parentNode")) {
          dagreGraph.setNode(node.id, {
            width: node.width || 0,
            height: node.height || 0,
          });
        }
      });

      edges.forEach((edge) => {
        dagreGraph.setEdge(edge.source, edge.target);
      });

      categories.forEach((node) => {
        dagreGraph.setNode(node, { width: nodeWidth, height: nodeHeight });
      });

      dagre.layout(dagreGraph);

      let tempNodes = nodes.map((node) => ({ ...node })); // Create a shallow copy of nodes
      for (let [index, node] of nodes.entries()) {
        if (!node.id.includes("-parentNode")) {
          const nodeWithPosition = dagreGraph.node(node.id);

          node.position = {
            x: bucketPositions[node.data.department] * 10,
            y: nodeWithPosition.y + 30,
          };

          let targetIndex = tempNodes.findIndex((n) => {
            return (
              n.position.x === node.position.x &&
              n.position.y === node.position.y
            );
          });

          if (targetIndex >= 0) {
            let oldNodePositions = tempNodes[targetIndex].position;

            // Shift old node to left
            tempNodes[targetIndex].position = {
              x: oldNodePositions.x - concurrentNodeGap / 1.5,
              y: oldNodePositions.y,
            };

            // Shift new node to right
            node.position = {
              x: node.position.x + concurrentNodeGap / 1.5,
              y: node.position.y,
            };
          }

          node.data.backgroundColor =
            colorList[categories.indexOf(node.data.department)];
          tempNodes[index] = node;
        }
      }

      // For Header Nodes
      let categoryNodes = categories.map((category, index) => {
        let categoryNode = {
          id: category,
          data: { label: category, backgroundColor: colorList[index] },
          draggable: false,
          connectable: false,
          deletable: false,
          type: "custom",
        };

        categoryNode["position"] = {
          x: bucketPositions[category] * 10,
          y: 0,
        };

        return categoryNode;
      });

      let graphNodes = tempNodes.concat(categoryNodes);

      resolve({ nodes: graphNodes, edges });
    } catch (error) {
      reject(error);
    }
  });
};
