import { createGlobalState } from "react-hooks-global-state";

const { setGlobalState, useGlobalState } = createGlobalState({
  error: {
    open: false,
    message: "Error Occured, please contact the admin team!!",
    type: "error",
    code: 600
  },
  userDetails: {

  },
  basicAnalytics: {},
  advancedAnalytics: {},
  banner:{
    open: false,
    message: "The site is under Maintenance",
    type: "info",
  },
  workflowView:{},
  caseList:{},
  analyticsFilters:{
    averageProcessingTime: null,
    resolutionRatebyTime: null,
    agentLeaderboard: null,
    invalidClaimsByCustomer: null,
    claimsByReasonCodes: null,
    claimByAgeBucket: null,
    claimCostByCustomer: null,
  },
  chatBotMessage: true
});

export { useGlobalState, setGlobalState };
