import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./index.css";

import { useTheme } from "@emotion/react";
import { chatGPTAPI } from "api";
import MDChip from "components/MDChip";
import ClaimNavbar from "examples/Navbars/ClaimNavbar";
import { useStyles } from "examples/Navbars/ClaimNavbar/styles";
import Confirmation from "examples/dialogs/Confirmation";
import SearchBar from "examples/searchBar/SearchBar";
import LoadingOverlay from "react-loading-overlay";
import ChatPanel from "./components/chatPanel/ChatPanel";
import SessionNameEdit from "./components/editSessionName";

export default function MainChat({ chatId }) {
  const navigation = useNavigate();
  const [sessions, setSessions] = useState([]);
  const [filteredSessions, setFilteredSessions] = useState([]);
  const [selectedSession, setSelectedSession] = useState(null);
  const [sessionDetails, setSessionDetails] = useState({});
  const [isLoadingChat, setIsLoadingChat] = useState(false);
  const [atFeatures, setAtFeatures] = useState({});
  const [showEditSession, setShowEditSession] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [editSession, setEditSession] = useState(null);

  const searchRef = useRef(null);
  const searchSessionsByName = () => {
    const filterBy = searchRef?.current.value;
    const filteredResult = sessions?.filter(
      (data) =>
        data?.label?.toLowerCase().includes(filterBy.toLowerCase()) ||
        data?._id?.includes(filterBy.toLowerCase())
    );
    setFilteredSessions(filteredResult);
  };

  const ClearAll = () => {
    sessions.forEach((session) => {
      deleteSession(session);
    });
  };

  const getSessions = () => {
    setIsLoadingChat(true);
    chatGPTAPI
      .getSessionList()
      .then((res) => {
        if (res.status == 200) {
          let tempSessions = res.data;
          tempSessions.sort((a, b) => {
            return a.timestamp_creation > b.timestamp_creation ? -1 : 1;
          });
          setSessions(tempSessions);
          setFilteredSessions(tempSessions);
          if (tempSessions.length > 0) {
            selectSession(tempSessions[0]);
          }
          getAvailableFeatureSelect();
          setIsLoadingChat(false);
        }
      })
      .catch((error) => {
        setIsLoadingChat(false);
      });
  };

  const createNewSession = () => {
    setIsLoadingChat(true);
    chatGPTAPI
      .createNewSession()
      .then((res) => {
        if (res.status == 200) {
          setIsLoadingChat(false);
          getSessions();
        }
      })
      .catch((error) => {
        setIsLoadingChat(false);
      });
  };

  const getAvailableFeatureSelect = () => {
    chatGPTAPI
      .getAllFeaturesAvailable()
      .then((res) => {
        if (res.status == 200) {
          setAtFeatures(res.data);
        }
      })
      .catch((error) => {});
  };

  const deleteSession = (session) => {
    setIsLoadingChat(true);
    chatGPTAPI
      .deleteSession({ session_id: session._id })
      .then((res) => {
        if (res.status == 200) {
          setIsLoadingChat(false);
          if (session._id == selectedSession._id) {
            setSelectedSession(null);
          }
          getSessions();
        }
      })
      .catch((error) => {
        setIsLoadingChat(false);
      });
  };

  const updateSession = (session, summary) => {
    setIsLoadingChat(true);
    chatGPTAPI
      .updateSession({ session_id: session._id, summary: summary })
      .then((res) => {
        if (res.status == 200) {
          setIsLoadingChat(false);
          if (session._id == selectedSession._id) {
            setSelectedSession(session);
          }
          getSessions();
        }
      })
      .catch((error) => {
        setIsLoadingChat(false);
      });
  };

  const selectSession = (session) => {
    setSelectedSession(session);
  };

  const handleTitleEdit = (session) => {
    setEditSession(session);
    setShowEditSession(true);
  };

  useEffect(() => {
    getSessions();
  }, []);
  const styles = useStyles();
  const { palette, functions, typography } = useTheme();
  const { primary, white, secondary, warning, error, text } = palette;
  const { pxToRem } = functions;
  return (
    <DashboardLayout>
      <ClaimNavbar
        icon={
          <MDBox sx={styles.iconHolder}>
            <span
              style={{ fontSize: "1.3em" }}
              class="material-symbols-outlined"
            >
              chat
            </span>
          </MDBox>
        }
        leftContent={
          <MDBox sx={{ fontSize: "16px", fontWeight: "500" }}>Chat Intel</MDBox>
        }
      />

      <MDBox
        sx={{ display: "flex", gap: "1rem", height: "calc(100vh - 112px)" }}
        mt={2}
      >
        <MDBox
          sx={{
            width: "30%",
            maxWidth: "400px",
            backgroundColor: white.main,
            minHeight: 100,
            borderRadius: "10px",
            padding: "1rem",
            height: "100%",
          }}
        >
          <MDBox
            sx={{
              display: "flex",
              gap: "5px",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <MDChip
              sx={{
                backgroundColor: primary.main,
                padding: "5px 10px",
                color: white.main,
                fontWeight: "bold",
                cursor: "pointer",
              }}
              label={"New Conversation"}
              avatar={
                <MDBox
                  sx={{
                    fontSize: "15px !important",
                    backgroundColor: "#00000000 !important",
                    padding: "0 5px",
                  }}
                >
                  +
                </MDBox>
              }
              onClick={createNewSession}
            />
            <MDBox
              sx={{
                textDecoration: "underline",
                cursor: "pointer",
              }}
              color={primary.main}
              onClick={() => setShowDeleteConfirmation(true)}
            >
              Clear All
            </MDBox>
          </MDBox>

          <MDBox mt={1} mb={2}>
            <SearchBar
              handleKeyUp={searchSessionsByName}
              inputRef={searchRef}
              placeholder={"Search conversation"}
              sx={{ border: "none", backgroundColor: primary.light }}
              fontColor={primary.main}
            />
          </MDBox>

          <MDBox
            sx={{
              borderTop: "1px solid " + text.light,
              height: "calc(100% - 90px)",
              overflow: "auto",
              width: "100%",
            }}
          >
            {filteredSessions.map((session) => (
              <MDBox
                px={2}
                my={1}
                sx={{
                  display: "flex",
                  height: "2rem",
                  gap: "5px",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: "50px",
                  backgroundColor:
                    selectedSession?._id == session._id ? "primary.light" : "",
                  width: "100%",
                  cursor: "pointer",
                  "&:hover": { backgroundColor: primary.light },
                }}
                onClick={() => {
                  selectSession(session);
                }}
              >
                <MDBox
                  sx={{
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                    width: "75%",
                  }}
                >
                  <span
                    class="material-symbols-outlined"
                    style={{
                      color: text.main,
                      fontSize: "1.4em",
                    }}
                  >
                    sms
                  </span>
                  <MDBox
                    sx={{
                      width: "100%",
                      overflow: "hidden",
                      textWrap: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                    color={text.main}
                  >
                    {session.label ? session.label : session._id}
                  </MDBox>
                </MDBox>
                <MDBox sx={{ display: "flex", gap: "5px", width: "55px" }}>
                  {/* <Tooltip title={"Edit Name"} className={"font-unset"}> */}
                  <span
                    class="material-symbols-outlined"
                    style={{
                      color: primary.main,
                      padding: "5px",
                      cursor: "pointer",
                      fontSize: "1.3em",
                    }}
                    onClick={() => handleTitleEdit(session)}
                  >
                    edit
                  </span>
                  {/* </Tooltip> */}
                  {/* <Tooltip title={"Delete Session"}> */}
                  <span
                    class="material-symbols-outlined"
                    style={{
                      color: error.main,
                      padding: "5px",
                      cursor: "pointer",
                      fontSize: "1.3em",
                    }}
                    onClick={() => deleteSession(session)}
                  >
                    delete
                  </span>
                  {/* </Tooltip> */}
                </MDBox>
              </MDBox>
            ))}
          </MDBox>
        </MDBox>

        <MDBox
          sx={{
            flex: "1",
            "& ._loading_overlay_overlay": { borderRadius: "10px" },
            "& ._loading_overlay_wrapper": { height: "100%" },
            height: "100%",
            width: "70%",
          }}
        >
          <LoadingOverlay
            active={isLoadingChat}
            spinner
            styles={{ width: "100%" }}
          >
            <ChatPanel
              selectedSession={selectedSession}
              mentionFeatures={atFeatures}
              handleTitleEdit={handleTitleEdit}
              setIsLoadingChat={setIsLoadingChat}
            />
          </LoadingOverlay>
        </MDBox>
      </MDBox>

      {showEditSession && (
        <SessionNameEdit
          open={true}
          handleClose={() => {
            setShowEditSession(false);
          }}
          handleSessionNameEdit={updateSession}
          session={editSession}
        />
      )}
      <Confirmation
        open={showDeleteConfirmation}
        onClose={() => {
          setShowDeleteConfirmation(false);
        }}
        onAccept={ClearAll}
        title={"Do you really want to delete all Conversation?"}
        acceptLable={"Delete All"}
      />
    </DashboardLayout>
  );
}
