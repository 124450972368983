import { chatGPTAPI } from "api";
import { useGlobalState } from "Global";
import { useEffect, useState } from "react";

const useChats = (sessionId, setIsLoadingChat) => {
  const [conversion, setConversation] = useState();
  const [filterdConversion, setFilterdConversion] = useState();
  const [userDetails] = useGlobalState("userDetails");

  useEffect(() => {
    getConversation(sessionId);
  }, [sessionId]);

  const getConversation = (sessionId) => {
    if (sessionId){
      setIsLoadingChat(true)
      chatGPTAPI
        .getSessionConversation({ session_id: sessionId })
        .then((res) => {
          if (res.status == 200) {
            let tempConversations = res.data.conversation.map((message, index) => {
              if (message.role === "user") {
                return {
                  ...message,
                  id: index + 1,
                  sender: userDetails.email,
                };
              } else {
                return {
                  ...message,
                  id: index + 1,
                  sender: "AI",
                };
              }
            });
            setFilterdConversion(tempConversations);
            setConversation(tempConversations);
          }
        })
        .catch((error) => {}).finally(() => {
          setIsLoadingChat(false)
        });
      }
  };

  return {
    conversion: conversion,
    setConversation: setConversation,
    filterdConversion: filterdConversion,
    setFilterdConversion: setFilterdConversion,
    userDetails: userDetails,
  };
};

export default useChats;
