import { useTheme } from "@emotion/react";
import { Card, CircularProgress, InputBase } from "@mui/material";
import { chatGPTAPI } from "api";
import MDBox from "components/MDBox";
import SearchBar from "examples/searchBar/SearchBar";
import { setGlobalState } from "Global";
import { useEffect, useRef, useState } from "react";
import ChatCard from "./ChatCard";
import useChats from "./hooks/useChats";

const ChatPanel = ({
  selectedSession,
  mentionFeatures,
  handleTitleEdit,
  setIsLoadingChat,
  iconsStyle,
}) => {
  const { palette, functions, typography } = useTheme();
  const { primary, white, secondary, warning, error, text } = palette;
  const { pxToRem } = functions;
  const inputRef = useRef(null);
  const searchRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const {
    conversion,
    setConversation,
    filterdConversion,
    setFilterdConversion,
    userDetails,
  } = useChats(selectedSession?._id, setIsLoadingChat);

  function detectURL(message) {
    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return message.replace(urlRegex, function (urlMatch) {
      return '<a href="' + urlMatch + '">' + urlMatch + "</a>";
    });
  }

  const handleSendMessage = () => {
    sendMessage(inputRef?.current.value);
  };

  const searchMessagessBysummary = () => {
    const filterBy = searchRef?.current.value;
    const filteredResult = conversion?.filter(
      (data) =>
        data?.summary?.includes(filterBy) || data?.content?.includes(filterBy)
    );
    setFilterdConversion(filteredResult);
  };

  const sendMessage = (message) => {
    if (!isLoading) {
      setIsLoading(true);
      setIsLoadingChat(true);
      let newMessages = [...conversion];

      let messageFormat = detectURL(message);
      let newMessageItem = {
        id: conversion.length + 1,
        sender: userDetails?.email,
        senderAvatar: "https://i.pravatar.cc/150?img=32",
        content: messageFormat,
        role: "user",
      };

      newMessages.push(newMessageItem);
      // setFilterdConversion(newMessages);
      // setConversation(newMessages);

      getSql(newMessages);
    }
  };

  const getSql = async (messagesList, sqlDetails = {}, retryCount = 0) => {
    sqlDetails = {
      ...sqlDetails,
      error_encountered: sqlDetails.error_encountered
        ? sqlDetails.error_encountered
        : false,
      session_id: selectedSession?._id,
    };

    chatGPTAPI
      .getSql(sqlDetails, messagesList)
      .then((res) => {
        if (res.status == 200) {
          setFilterdConversion(res.data);
          setConversation(res.data);
        } else if (res.status == 202) {
          setGlobalState("error", {
            open: true,
            message: res.data,
            type: "error",
          });
        }
        setIsLoading(false);
        setIsLoadingChat(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setIsLoadingChat(false);
      });
  };
  const contentBoxRef = useRef(null);
  useEffect(() => {
    if (contentBoxRef.current && contentBoxRef.current.scrollHeight) {
      setTimeout(() => {
        if (contentBoxRef.current) {
          contentBoxRef.current.scrollTop = contentBoxRef.current.scrollHeight;
        }
      }, 1000);
    }
  }, [conversion, contentBoxRef]);

  return (
    <MDBox sx={{ padding: "1rem", height: "100%", width: "100%" }}>
      <MDBox
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "1em",
          paddingBottom: "1em",
          borderBottom: "1px solid " + text.light,
        }}
      >
        <MDBox
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <MDBox
            sx={{
              maxWidth: "300px",
              overflow: "hidden",
              textWrap: "nowrap",
              textOverflow: "ellipsis",
            }}
            color={text.main}
          >
            {selectedSession?.label}
          </MDBox>
          {handleTitleEdit && (
            <span
              class="material-symbols-outlined"
              style={{
                color: primary.main,
                padding: "5px",
                cursor: "pointer",
                fontSize: "1.3em",
              }}
              onClick={() => handleTitleEdit(selectedSession)}
            >
              edit
            </span>
          )}
        </MDBox>
        <MDBox sx={{ width: "40%" }}>
          <SearchBar
            placeholder={"Search Message"}
            handleKeyUp={searchMessagessBysummary}
            inputRef={searchRef}
            sx={{
              border: "none",
              backgroundColor: white.main,
              borderRadius: "8px",
            }}
            fontColor={primary.main}
          />
        </MDBox>
      </MDBox>
      <>
        {conversion?.length === 0 ? (
          <MDBox mt={2} sx={{ height: "calc(100% - 115px)", overflow: "auto" }}>
            <EmptyMessage
              handleSendMessage={sendMessage}
              iconsStyle={iconsStyle}
            />
          </MDBox>
        ) : (
          <MDBox
            ref={contentBoxRef}
            mt={2}
            sx={{
              height: "calc(100% - 115px)",
              overflow: "auto",
              scrollBehaviour: "smooth",
            }}
          >
            {filterdConversion?.map((data) => (
              <ChatCard
                data={data}
                userDetails={userDetails}
                isLoading={isLoading}
              />
            ))}
          </MDBox>
        )}
        <MDBox sx={{ paddingTop: "10px" }}>
          <BottomInput
            placeHolder={"What is on your mind?"}
            icon={<span class="material-symbols-outlined">send</span>}
            handleClick={handleSendMessage}
            inputRef={inputRef}
            isLoading={isLoading}
          />
        </MDBox>
      </>
    </MDBox>
  );
};

export default ChatPanel;

const BottomInput = ({
  placeHolder,
  icon,
  handleClick,
  inputRef,
  style,
  isLoading,
}) => {
  const { palette, typography, functions } = useTheme();
  const { primary, white, text } = palette;
  const { size } = typography;
  const { pxToRem } = functions;

  return (
    <MDBox
      sx={{
        display: "flex",
        gap: "8px",
        ...style,
      }}
    >
      <InputBase
        inputRef={inputRef}
        placeholder={placeHolder}
        sx={{
          backgroundColor: white.main,
          borderRadius: "8px",
          padding: "5px",
          paddingLeft: "12px",
          fontSize: size["sm"],
          width: "100%",
          color: text.secondary,
          "& .MuiInputBase-input::placeholder": {
            color: text.disabled,
            opacity: 1,
          },
        }}
      />
      {(icon || handleClick) && (
        <MDBox
          sx={{
            backgroundColor: primary.main,
            color: white.main,
            height: "40px",
            width: "50px",
            fontSize: pxToRem(22),
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "8px",
            cursor: "pointer",
          }}
          onClick={isLoading ? () => {} : () => handleClick()}
        >
          {isLoading ? (
            <CircularProgress
              sx={{ height: "1em !important", width: "1em !important" }}
              color="white"
            />
          ) : (
            icon
          )}
        </MDBox>
      )}
    </MDBox>
  );
};

const EmptyMessage = ({ handleSendMessage, iconsStyle }) => {
  return (
    <MDBox
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <lottie-player
        onClick={() => setShowAIAgent(!showAIAgent)}
        src="https://lottie.host/56b2c8f6-5525-4c17-96be-87ad65de28de/LL0Sw3bEMT.json"
        background="transparent"
        speed="1"
        style={{
          width: "8rem",
          height: "8rem",
          ...iconsStyle,
        }}
        loop
        autoplay
        direction="1"
        mode="normal"
      ></lottie-player>
      {/* <img height={"60%"} width={"30%"} src={botImage} /> */}
      <MDBox>
        Hi!! I am your personal chatbot. You can ask me any Question regarding
        the database.{" "}
      </MDBox>
      <MDBox>
        <b>Note:</b> Please select either a previous session to continue
        conversation or create a new session
      </MDBox>
      <MDBox
        my={1}
        sx={{
          display: "flex",
          width: "100%",
          gap: "2rem",
          justifyContent: "center",
        }}
      >
        <Card
          sx={{
            padding: ".5rem",
            display: "block",
            cursor: "pointer",
            backgroundColor: "transparent !important",
            border: "1px solid lightgrey",
            ":hover": {
              boxShadow: "2px 2px 5px 2px lightgrey",
            },
          }}
          onClick={() =>
            handleSendMessage("Get number of cases assigned per agent")
          }
        >
          <MDBox>Get number of cases assigned per agent</MDBox>
        </Card>
        <Card
          sx={{
            padding: ".5rem",
            display: "block",
            cursor: "pointer",
            backgroundColor: "transparent !important",
            border: "1px solid lightgrey",
            ":hover": {
              boxShadow: "2px 2px 5px 2px lightgrey",
            },
          }}
          onClick={() =>
            handleSendMessage("Get average time taken to resolve per case")
          }
        >
          <MDBox sx={{ whiteSpace: "nowrap" }}>
            Get average time taken to resolve per case
          </MDBox>
        </Card>
      </MDBox>
    </MDBox>
  );
};
