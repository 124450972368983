import { useTheme } from "@emotion/react";
import { Dialog, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import { deleteSvg, plusSvg } from "examples/svgIcons/svgIcons";
import { useGlobalState } from "Global";
import { memo } from "react";
import { Handle, Position } from "reactflow";

const CustomNode = ({ id, data }) => {
  const { palette, typography } = useTheme();
  const { primary, white, text, error } = palette;
  const [userDetails] = useGlobalState("userDetails");

  return (
    <>
      <MDBox
        sx={{
          fontSize: "1.8em",
          border: "1px solid " + data.backgroundColor,
          borderRadius: "5px",
          padding: "5px 15px",
          position: "relative",
          ...(data?.department
            ? {
                boxShadow: "0 0 4px 0 " + data.backgroundColor,
                backgroundColor: white.main,
              }
            : {
                backgroundColor: data.backgroundColor,
                borderRadius: "100px",
                color: white.main,
                fontWeight: "500",
                textAlign: "center",
              }),
        }}
        title="click to add a child node"
        onClick={(e) => {
          e.stopPropagation();
          if (data?.onNodeClick && userDetails?.permission === "superuser")
            data?.onNodeClick(id, "update");
        }}
      >
        {data.label}
        <Handle type="target" position={Position.Top} isConnectable={false} />
        <Handle
          type="source"
          position={Position.Bottom}
          isConnectable={false}
        />
        {data?.department && userDetails?.permission === "superuser" && (
          <Tooltip title={"Add Child Node"}>
            <span
              onClick={(e) => {
                e.stopPropagation();
                if (
                  userDetails?.permission === "superuser" &&
                  data?.onNodeClick
                )
                  data?.onNodeClick(id, "add");
              }}
              class="material-symbols-outlined"
              style={{
                color: text.disabled,
                cursor: "pointer",
                position: "absolute",
                left: "50%",
                transform: "translate(-50%, 30px)",
                background: white.main,
                padding: "5px",
                borderRadius: "100%",
                border: "1px solid " + data.backgroundColor,
                fontSize: ".9em",
                lineHeight: ".6em",
              }}
            >
              {plusSvg(data.backgroundColor)}
            </span>
          </Tooltip>
        )}
        {data?.department &&
          userDetails?.permission === "superuser" &&
          !data?.isFirstStep && (
            <Tooltip title={"Delete Node"}>
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  if (
                    userDetails?.permission === "superuser" &&
                    data?.onNodeClick
                  )
                    data?.onNodeClick(id, "delete");
                }}
                class="material-symbols-outlined"
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  right: "0",
                  top: "0",
                  transform: "translate(50%, -50%)",
                  background: white.main,
                  padding: "7px",
                  borderRadius: "100%",
                  fontSize: "1em",
                  boxShadow: "0 3px 5px 1px #c8c8c8",
                  lineHeight: ".6em",
                }}
              >
                {deleteSvg(data.backgroundColor)}
              </span>
            </Tooltip>
          )}
      </MDBox>
    </>
  );
};

export default memo(CustomNode);
