import { useEffect, useMemo, useState } from "react";

// prop-types is a library for typechecking of props
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import PropTypes, { number } from "prop-types";

// react-table components
import {
  useAsyncDebounce,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

// @mui material components
import Autocomplete from "@mui/material/Autocomplete";
import Icon from "@mui/material/Icon";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";

//  React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";

//  React example components
import { useTheme } from "@emotion/react";
import { makeStyles, styled } from "@material-ui/core";
import {
  CircularProgress,
  Collapse,
  Divider,
  InputBase,
  Tooltip,
} from "@mui/material";
import Dropdown from "components/controls/Dropdown";
import MDButton from "components/MDButton";
import MDChip from "components/MDChip";
import ButtonGroup from "examples/ButtonGroup";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import { exportToExcel } from "react-json-to-excel";
import HeaderSkeleton from "skeletons/tables/headerSkeleton";
import RowSkeleton from "skeletons/tables/rowSkeleton";
import { CustomPopper } from "components/controls/CustomPopper";
import { convertKeyToName } from "utils/conversion";
import { applyStyleToEachTableCell } from "./utils";
import { MDTableContainer } from "components/MDTableContainer/MDTableContainer";
import { OutlinedButton } from "components/MDButton/button";
import EmptyResult from "utils/emptyResult";

const caseStateMapping = {
  New: "warning",
  "In-Progress": "info",
  Resolved: "success",
};

const useStyles = makeStyles({
  popper: { minWidth: "max-content !important" },
});

const daysList = [10, 20, 30, 60, 90, 180];

function DataTable({
  entriesPerPage,
  canSearch,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  noEndBorder,
  rowClick,
  selectall,
  submit,
  pageDetails,
  columnFilter,
  handleColumnFilter,
  handleDaysFilter,
  filters,
  filterOptions,
  handleFilterChange,
  downloadable,
  downloadableBulk,
  handleDownloadBulk,
  downloadFileName,
  customPagination,
  ignoreFilterList,
  pageInfo,
  customStyle,
  rowColor,
  isLoading,
  AIRecommended = false,
  handleSearchChange,
  setApplyFilterFlag,
  tableHeight,
  showControlls,
}) {
  const defaultValue = entriesPerPage.defaultValue
    ? entriesPerPage.defaultValue
    : 50;
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries.map((el) => el.toString())
    : ["5", "10", "15", "20", "25", "50", "100", "500"];

  const [columnId, setColumnId] = useState();
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(
    () =>
      handleSearchChange
        ? applyStyleToEachTableCell(table.columns, table.rows)
        : table.rows,
    [table]
  );
  const [showFilters, setShowFilters] = useState(false);
  // const [filtersCount, setFiltersCount] = useState(0)
  // useEffect(()=> {

  // }, [filters])
  const getFiltersCount = () => {
    let count = 0;
    if (filters){
      Object.keys(filters).forEach((filterName) => {
        if (!ignoreFilterList.includes(filterName)) {
          count += filters[filterName]?.length;
        }
      });
    }
    return count;
  };
  const filtersCount = getFiltersCount();

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 }, autoResetPage: false },

    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const classes = useStyles();

  const [openFilterOptions, setOpenFilterOptions] = useState({ new: false });

  const [anchorEl, setAnchorEl] = useState(null);
  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  const [customPageOptions, setCustomPageOptions] = useState([]);
  const [customPageIndex, setCustomPageIndex] = useState(pageIndex);
  const [canPreviousPage, setCanPreviousPage] = useState(true);
  const [canNextPage, setCanNextPage] = useState(true);
  const [pageNumberInput, setPageNumberInput] = useState();

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value) => setPageSize(value);
  pageDetails?.setPageSize(pageSize);
  pageDetails?.setPageNo(customPageIndex + 1);

  // Create a list of page indexes for page options
  useEffect(() => {
    if (customPagination) {
      let tempPageOptions = [];
      for (let i = 0; i < pageInfo.pages; i++) {
        tempPageOptions.push(i);
      }
      setCustomPageOptions(tempPageOptions);
    }
  }, [pageInfo]);

  useEffect(() => {
    if (!customPagination) {
      setCustomPageOptions(pageOptions);
    }
  }, [pageOptions]);

  useEffect(() => {
    if (customPageIndex == 0) {
      setCanPreviousPage(false);
    } else {
      setCanPreviousPage(true);
    }
    if (customPageIndex >= customPageOptions.length - 1) {
      setCanNextPage(false);
    } else {
      setCanNextPage(true);
    }
  }, [customPageIndex]);

  // Render the paginations
  const renderPagination = customPageOptions.map((option) => (
    <MDPagination
      item
      key={option}
      onClick={() => handlePageSelect(Number(option))}
      active={customPageIndex === option}
    >
      {option + 1}
    </MDPagination>
  ));

  const handlePageSelect = (indexValue) => {
    if (customPagination) {
      // Call handleFilter Change with pagination index
      handleFilterChange({ target: { name: "offset", value: indexValue } });
      setCustomPageIndex(indexValue);
      setApplyFilterFlag(Math.random());
    } else {
      // Call gotoPage with the index n.o
      gotoPage(indexValue);
      setCustomPageIndex(indexValue);
      setPageNumberInput(indexValue+1)
    }
  };

  const filterColumns = (data) => {
    // Get column names
    const headerColumns = Object.keys(data[0]);
    let headers = [];
    headerColumns.forEach((col, idx) => {
      headers.push({ label: col.toUpperCase(), key: col });
    });

    return headers;
  };

  useEffect(() => {
    setPageNumberInput(customPageOptions[customPageIndex] + 1);
  }, [customPageOptions]);
  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) => {
    setPageNumberInput(value);
    if (value) {
      value > customPageOptions.length || value < 0
        ? handlePageSelect(0)
        : handlePageSelect(Number(value) - 1);
    }
  };

  const handleEntriesPerPageChange = (event, newValue) => {
    setEntriesPerPage(parseInt(newValue, 10));
    if (customPagination) {
      handleFilterChange({
        target: { name: "limit", value: parseInt(newValue, 10) },
      });
      setCustomPageIndex(0);
      setApplyFilterFlag(Math.random());
    }
  };

  // Search input value state
  const [search, setSearch] = useState(handleSearchChange ? globalFilter : "");

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  const customNextPage = () => {
    if (customPageIndex + 1 < customPageOptions.length)
      handlePageSelect(customPageIndex + 1);
  };

  const customPreviousPage = () => {
    if (customPageIndex - 1 >= 0) handlePageSelect(customPageIndex - 1);
  };

  // Setting the entries starting point
  const entriesStart =
    customPageIndex === 0
      ? customPageIndex + 1
      : customPageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (customPageIndex === 0) {
    entriesEnd = pageSize;
  } else if (customPageIndex === customPageOptions.length - 1) {
    entriesEnd = customPagination ? entriesStart + rows.length - 1 : rows.length;
  } else {
    entriesEnd = pageSize * (customPageIndex + 1);
  }

  function getStyles(option, selectedList) {
    return {
      fontWeight: selectedList.includes(
        option.email_id ? option.email_id : option._id ? option._id : option
      )
        ? "regular"
        : "medium",
    };
  }

  const { palette, functions, typography } = useTheme();
  const { primary, white, secondary, warning, text } = palette;
  const { pxToRem } = functions;

  let searchTimeout = null;
  const searchDebouncer = (search) => {
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => handleSearchChange(search), 300);
  };
  useEffect(() => {
    if (handleSearchChange) {
      if (search) searchDebouncer(search);
      return () => {
        if (searchTimeout) {
          clearTimeout(searchTimeout);
        }
      };
    }
  }, [search]);

  return ( 
    <MDBox sx={{height: '100%', backgroundColor: white.main, borderRadius: '10px'}}>
      <MDBox
        py={2}
        px={3}
        sx={{
          display: showControlls !== false ? "flex" : "none",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "15px",
        }}
        >
        <MDBox
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "15px",
            flex: "1",
          }}
        >
          {canSearch && (
            <MDBox
              sx={{
                display: "flex",
                gap: "5px",
                padding: "5px 10px",
                alignItems: "center",
                borderRadius: "8px",
                backgroundColor: primary.light,
                width: "100%",
                height: "100%",
                maxWidth: "392px",
              }}
            >
              <MDBox
                sx={{
                  fontSize: pxToRem(22),
                  color: primary.main,
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <SearchOutlinedIcon />
              </MDBox>
              <InputBase
                sx={{
                  fontSize: pxToRem(14),
                  color: text.disabled,
                  width: "100%",
                  "& .MuiInputBase-input": {
                    width: "100%",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: text.disabled,
                    opacity: 1,
                  },
                }}
                placeholder={"Search"}
                onChange={(e) => {
                  setSearch(e.target.value);
                  if (!handleSearchChange) {
                    onSearchChange(e.target.value);
                  } else if (e.target.value == "") {
                    handleSearchChange("");
                  }
                }}
              />
            </MDBox>
          )}

          {filters && (
            <MDBox sx={{ position: "relative" }}>
              {filtersCount !== 0 && <MDBox
                sx={{
                  position: "absolute",
                  backgroundColor: primary.main,
                  top: '-5px',
                  right: '-5px',
                  width: '20px',
                  height: '20px',
                  textAlign: 'center',
                  fontSize: '12px',
                  borderRadius: '100px',
                }}
                color={white.main}
              >
                {filtersCount}
              </MDBox>}
              <OutlinedButton
                sx={{ backgroundColor: showFilters ? "#D6D9FF" : "" }}
                onClick={() => setShowFilters(!showFilters)}
                name={"Filter"}
                icon={
                  <Icon className={"font-unset"} fontSize="1em">
                    filter_alt
                  </Icon>
                }
              />
            </MDBox>
          )}

          {submit && (
            <OutlinedButton
              sx={{ backgroundColor: showFilters ? "#D6D9FF" : "" }}
              onClick={submit.handleClick}
              name={submit.value}
              icon={
                submit.loading ? (
                  <CircularProgress
                    color="primary"
                    sx={{ height: "20px !important", width: "20px !important" }}
                  />
                ) : (
                  <Icon className={"font-unset"} fontSize="1em">
                    {submit.iconName}
                  </Icon>
                )
              }
            />
          )}

          {downloadable && data.length != 0 && (
            <OutlinedButton
              name={"Download"}
              onClick={() => {
                if (downloadable.type == "bulk") {
                  handleDownloadBulk(filters, data);
                } else if (downloadable.type == "mail") {
                  handleDownloadBulk(filters, data, true);
                } else {
                  exportToExcel(
                    [{ sheetName: "Details", details: data }],
                    downloadFileName ? downloadFileName : "DataFile",
                    true
                  );
                }
              }}
              icon={
                <Icon className={"font-unset"} fontSize="1em">
                  download
                </Icon>
              }
            />
          )}
        </MDBox>

        <MDBox
          sx={{
            display: "flex",
            gap: "15px",
            alignItems: "center",
          }}
        >
          {entriesPerPage || canSearch ? (
            <MDBox display="flex" sx={{ margin: "0" }}>
              {entriesPerPage && (
                <MDBox display="flex" alignItems="center">
                  <MDBox
                    sx={{
                      fontSize: typography.size["sm"],
                      fontWeight: typography.fontWeightMedium,
                    }}
                    color={text.disabled}
                  >
                    Rows per page:
                  </MDBox>
                  <Autocomplete
                    disableClearable
                    value={pageSize.toString()}
                    options={entries}
                    onChange={(event, newValue) => {
                      handleEntriesPerPageChange(event, newValue);
                    }}
                    PopperComponent={CustomPopper}
                    sx={{
                      height: "100%",
                      width: "70px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "& .MuiInputBase-root": {
                        padding: "0",
                      },
                    }}
                    renderInput={(params) => <MDInput {...params} />}
                  />
                </MDBox>
              )}
            </MDBox>
          ) : null}

          {showTotalEntries && (
            <MDBox
              sx={{
                fontWeight: typography.fontWeightMedium,
                fontSize: typography.size["sm"],
                alignItems: "center",
                display: "flex",
              }}
            >
              <MDBox
                style={{
                  color: text.main,
                }}
              >
                {entriesStart} - {entriesEnd} &nbsp;
              </MDBox>
              <MDBox
                style={{
                  color: text.disabled,
                }}
              >
                of {customPagination ? pageInfo.count : rows.length}
              </MDBox>
            </MDBox>
          )}

          <MDBox
            sx={{
              fontSize: `${typography.size["lg"]} !important`,
              marginBottom: "3px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <PaginationArrow
              iconName={"keyboard_double_arrow_left"}
              onClick={() => handlePageSelect(0)}
              textColor={text.disabled}
            />
            <PaginationArrow
              iconName={"keyboard_arrow_left"}
              onClick={() => customPreviousPage()}
              textColor={text.main}
            />
            <MDInput
              inputProps={{
                type: "number",
                min: 1,
                max: customPageOptions.length,
              }}
              sx={{
                padding: "0",
                "& fieldset": {
                  border: "0",
                  padding: "0",
                },
                "& input": {
                  padding: "0",
                  textAlign: "center",
                  width: "auto",
                  "&::-webkit-inner-spin-button": {
                    "-webkit-appearance": "none",
                    margin: 0,
                  },
                  "&::-webkit-outer-spin-button": {
                    "-webkit-appearance": "none",
                    margin: 0,
                  },
                },
              }}
              value={pageNumberInput}
              onChange={handleInputPagination}
            />
            <PaginationArrow
              iconName={"keyboard_arrow_right"}
              onClick={customNextPage}
              textColor={text.main}
            />
            <PaginationArrow
              iconName={"keyboard_double_arrow_right"}
              onClick={() => handlePageSelect(customPageOptions.length - 1)}
              textColor={text.disabled}
            />
          </MDBox>
        </MDBox>
      </MDBox>
      {filters && (
        <Collapse in={showFilters}>
          <MDBox
            px={3}
            pb={2}
            pt={1}
            sx={{
              display: "flex",
            }}
          >
            <MDBox
              sx={{
                width: "70%",
                display: "flex",
                gap: "20px",
                flexWrap: "wrap",
              }}
            >
              {Object.keys(filters).map((filterName, index) => {
                if (!ignoreFilterList.includes(filterName))
                  return (
                    <MDBox
                      sx={{
                        width: "calc(33% - 20px)",
                      }}
                      key={index}
                    >
                      {filterOptions[filterName]?.length > 0 && (
                        <Dropdown
                          value={filters[filterName]}
                          onChange={(event, newValue) => {
                            handleFilterChange({
                              target: {
                                name: filterName,
                                value: newValue,
                              },
                            });
                          }}
                          options={filterOptions[filterName]}
                          getOptionLabel={(option) => {
                            return option?.name ? option.name : option;
                          }}
                          placeholder={(filters[filterName] != '')? "" : "Select"}
                          label={convertKeyToName(filterName)}
                        />
                      )}
                    </MDBox>
                  );
              })}
            </MDBox>

            <MDBox
              sx={{
                width: "30%",
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <MDChip
                sx={{
                  backgroundColor: secondary.main,
                  padding: "5px 40px",
                  color: white.main,
                  fontWeight: "bold",
                  cursor: "pointer",
                  fontSize: "14px",
                  height: "40px",
                  borderRadius: "100px",
                }}
                label={"Apply All Filters"}
                onClick={() => {
                  setShowFilters(!showFilters);
                  if (setApplyFilterFlag) setApplyFilterFlag(Math.random());
                }}
              />
            </MDBox>
          </MDBox>
        </Collapse>
      )}

      <MDTableContainer
        color={primary.main}
        sx={
          customStyle
            ? {...customStyle, height: tableHeight, position: 'relative'}
            : {
                boxShadow: "none",
                borderRadius: "0",
                height: tableHeight, overflow: 'auto', position: 'relative'
              }
        }
      >
        <Table {...getTableProps()}>
          <MDBox component="thead">
            {isLoading == true && headerGroups.length == 0 && (
              <HeaderSkeleton />
            )}
            {(isLoading == undefined || isLoading == false) &&
              headerGroups.map((headerGroup, index) => (
                <TableRow
                  {...headerGroup.getHeaderGroupProps()}
                  key={index}
                  sx={{
                    backgroundColor: primary.light,
                    color: text.header,
                    fontWeight: typography.fontWeightMedium,
                    fontSize: "13px",
                    border: "1px solid " + text.light,
                  }}
                >
                  {headerGroup.headers.map((column) => (
                    <DataTableHeadCell
                      {...column.getHeaderProps(
                        isSorted && column.getSortByToggleProps()
                      )}
                      width={column.width ? column.width : "auto"}
                      sorted={setSortedValue(column)}
                      align={"center"}
                    >
                      {convertKeyToName(column.render("id"))}
                    </DataTableHeadCell>
                  ))}
                </TableRow>
              ))}
          </MDBox>
          <TableBody {...getTableBodyProps()}>
            {isLoading == true && (
              <RowSkeleton columns={table.columns.length || 10} />
            )}
            {(isLoading == undefined || isLoading == false) &&
              page.map((row, key) => {
                prepareRow(row);
                const isIdMatched = rowColor
                  ? row.values[rowColor["id"]["name"]] ==
                    rowColor["id"]["value"]
                  : false;
                return (
                  <>
                    {!AIRecommended && (
                      <TableRow color="info" {...row.getRowProps()}>
                        {row.cells.map((cell, index) => (
                          <DataTableBodyCell
                            key={index}
                            bgcolor={
                              isIdMatched
                                ? rowColor["backgroundColor"]
                                : "white"
                            }
                            noBorder={noEndBorder && rows.length - 1 === key}
                            color={
                              isIdMatched
                                ? rowColor["color"]
                                : index % 2 === 0
                                  ? text.disabled
                                  : text.main
                            }
                            align={
                              cell.column.align ? cell.column.align : "center"
                            }
                            {...cell.getCellProps()}
                            onClick={() => {
                              if (rowClick && cell.column?.id != "action") {
                                rowClick(row);
                              }
                            }}
                            case_id={row.original?.caseId}
                            style={{ fontWeight: typography.fontWeightMedium }}
                          >
                            {cell.render("Cell")}
                          </DataTableBodyCell>
                        ))}
                      </TableRow>
                    )}
                    {AIRecommended && (
                      <TableRow
                        color="info"
                        {...row.getRowProps()}
                        sx={{ height: "6rem !important", position: "relative" }}
                      >
                        <>
                          {row.cells.map((cell, index) => (
                            <DataTableBodyCell
                              key={index}
                              bgcolor={
                                isIdMatched
                                  ? rowColor["backgroundColor"]
                                  : "white"
                              }
                              noBorder={noEndBorder && rows.length - 1 === key}
                              color={isIdMatched ? rowColor["color"] : "black"}
                              align={
                                cell.column.align ? cell.column.align : "left"
                              }
                              {...cell.getCellProps()}
                              handleClick={() => {
                                if (rowClick && cell.column?.id != "action") {
                                  rowClick(row);
                                }
                              }}
                              case_id={row.original?.caseId}
                              style={{
                                paddingBottom: "2rem",
                                borderBottom: "1px solid lightgrey",
                                borderRadius: "10px",
                              }}
                            >
                              {cell.render("Cell")}
                            </DataTableBodyCell>
                          ))}
                          <Divider />
                          <MDBox
                            sx={{
                              position: "absolute",
                              left: "2rem",
                              bottom: "0.5rem",
                            }}
                          >
                            <b> :</b> {row.original?.summary}
                          </MDBox>
                        </>
                      </TableRow>
                    )}
                  </>
                );
              })}
          </TableBody>
        </Table>
        {
          (customPagination ? pageInfo.count == 0 : rows.length == 0) &&  !isLoading && <EmptyResult text={"No Data Found"} style={{paddingTop: '60px'}}/>
        }

        {downloadableBulk && data.length != 0 && (
          <ButtonGroup
            handleDownloadBulk={() => {
              handleDownloadBulk(filters, data);
            }}
            handleDownloadBulkEmail={() => {
              handleDownloadBulk(filters, data, true);
            }}
          />
        )}
      </MDTableContainer>
    </MDBox>
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  entriesPerPage: {
    defaultValue: 50,
    entries: [5, 10, 15, 20, 25, 50, 100, 500],
  },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
  downloadable: true,
  customPagination: false,
  ignoreFilterList: [],
  pageInfo: { count: 0, pages: 0 },
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
};

export default DataTable;

const PaginationArrow = ({ iconName, onClick, textColor }) => {
  return (
    <Icon
      sx={{
        fontWeight: "600",
        cursor: "pointer",
        color: textColor,
        "&:hover": {
          transform: "scale(1.1)",
        },
      }}
      onClick={onClick}
    >
      {iconName}
    </Icon>
  );
};
