import { useTheme } from "@emotion/react";
import MDBox from "components/MDBox";
import AiResponseViewer from "./AiResponseViewer";

const sparkle = (color) => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.98215 0.81543C6.08638 4.50917 4.04069 7.1007 -0.000537872 7.78113C3.52785 9.03682 5.96216 11.2634 6.98215 14.7468C8.16854 11.4851 10.506 9.17097 13.9648 7.78113C10.8918 6.97735 8.61301 4.55578 6.98215 0.81543Z"
      fill={color}
    />
    <path
      d="M15.686 10.791C15.0847 13.2694 13.7123 15.0079 11.0008 15.4647C13.3683 16.3069 15.0017 17.8009 15.686 20.1377C16.4819 17.9491 18.0504 16.3972 20.3711 15.4647C18.3092 14.9251 16.7799 13.3002 15.686 10.791Z"
      fill={color}
    />
  </svg>
);

const AIResponseCard = ({ data, isLoading }) => {
  const { palette, functions, typography } = useTheme();
  const { primary, white, secondary, warning, error, text } = palette;
  const { pxToRem } = functions;

  return (
    <MDBox
      pb={2}
      ml={"calc(43px + 1em)"}
      sx={{ borderBottom: "1px solid " + text.light }}
    >
      <MDBox
        mb={1}
        sx={{
          display: "flex",
          gap: "5px",
          alignItems: "center",
          fontStyle: "italic",
        }}
        color={primary.main}
      >
        {sparkle(primary.main)}
        <MDBox color={primary.main}>Chat Intel v 1.0</MDBox>
      </MDBox>
      <MDBox mr={3}>
        {!isLoading && (
          <AiResponseViewer summary={data?.summary} sql={data?.content} />
        )}
      </MDBox>
    </MDBox>
  );
};

const UserQueryCard = ({ data, name }) => {
  const { palette, functions, typography } = useTheme();
  const { primary, white, secondary, warning, error, text } = palette;
  const { pxToRem } = functions;

  return (
    <MDBox py={2}>
      <MDBox sx={{ display: "flex", gap: "1em" }}>
        <MDBox
          sx={{
            height: "38px",
            width: "38px",
            fontSize: typography.size["lg"],
            fontWeight: "bold",
            background: primary.light,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "100%",
            position: "relative",
            zIndex: "1",
            border: `1px solid ${palette.primary.main}`,
            textTransform: "uppercase",
          }}
          title={name}
        >
          {name?.[0]}
        </MDBox>
        <MDBox
          sx={{
            backgroundColor: white.main,
            padding: "10px 15px",
            borderRadius: "10px",
            boxShadow: "0px 0px 8px 1px #0000000D",
            maxWidth: "500px",
          }}
          color={text.disabled}
        >
          {data?.content}
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

const ChatCard = ({ data, userDetails, isLoading }) => {
  return data.sender === "AI" || data.role === "system" ? (
    <AIResponseCard data={data} isLoading={isLoading} />
  ) : (
    <UserQueryCard
      data={data}
      name={userDetails?.full_name}
      isLoading={isLoading}
    />
  );
};

export default ChatCard;
