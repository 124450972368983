// @mui material components
import Grid from "@mui/material/Grid";

//  React components
import { Dialog, TextField } from "@mui/material";
import MDBox from "components/MDBox";

import { Box, Paper, Typography } from "@mui/material";
import MDChip from "components/MDChip";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomInput from "components/controls/CustomInput";

function SessionNameEdit({
  open,
  handleClose,
  session,
  handleSessionNameEdit,
}) {
  const [state, setState] = useState({ summary: session.label });
  const [agents, setAgents] = useState([]);
  const navigation = useNavigate();

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
    session.label = event.target.value;
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose(!open);
      }}
    >
      <Paper
        elevation={3}
        sx={{ marginRight: "0%", marginLeft: "0%", minWidth: "30rem" }}
      >
        <Box sx={{ padding: 3 }}>
          <Typography variant="h6" gutterBottom sx={{ paddingBottom: 2 }}>
            Change Session Name
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <CustomInput
                id="summary"
                label="Session Name"
                onChange={handleChange}
                name="summary"
                value={state.summary}
                error={state.summary?.length != 0 ? false : true}
                helperText={
                  state.summary?.length != 0 ? "" : "This field can't be empty"
                }
                fullWidth
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={2} md={12}>
              <MDBox
                borderRadius="lg"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                pt={2}
              >
                <MDChip
                  sx={(theme) =>({
                    backgroundColor: theme.palette.error.light + "45",
                    padding: "5px 10px",
                    color: theme.palette.error.main,
                    fontWeight: "bold",
                    cursor: "pointer",
                    border: "1px solid " + theme.palette.error.main,
                    width: "140px",
                    "&:hover": {
                      backgroundColor: theme.palette.error.light + "45",
                      boxShadow: "0 0 5px 1px " + theme.palette.error.main + "82",
                    },
                  })}
                  label={"Cancel"}
                  onClick={handleClose}
                />
                <MDChip
                  sx={(theme) => ({
                    backgroundColor: theme.palette.primary.main,
                    padding: "5px 10px",
                    color: theme.palette.white.main,
                    fontWeight: "bold",
                    cursor: "pointer",
                    width: "140px",
                  })}
                  label={"Update"}
                  onClick={() => {
                    handleClose();
                    handleSessionNameEdit(session, state.summary);
                  }}
                />
              </MDBox>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Dialog>
  );
}

export default SessionNameEdit;
